﻿.countryPanel {
    background: white;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: .25rem;
    padding: 5px;
    box-sizing: border-box;
    margin: 5px;

    &:hover {
        background-color: #1a2c74;
        color: #ffffff;
        text-decoration: none;
    }
}

.country-flag {
    width: 35px;
    padding: 2px;
}
