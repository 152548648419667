﻿body {
    background: $gray-800;
}

main {
    background: white;
}

li {
    margin-bottom: 10px;
}

// background areas
.bg-gray-light {
    background: darken($gray-100, 3%);
}

.bg-gray-dark {
    background: $gray-800;

    p {
        color: $gray-400;
        margin-bottom: 25px;

        @include media-breakpoint-up(md) {
            margin-bottom: 0px;
        }
    }

    a {
        color: $gray-400;

        &:hover {
            color: white;
            text-decoration: underline;
        }
    }
}

    .attractionExpander {
        background-color: #ededed;
    }

    .form-control:focus {
        box-shadow: 0 0 0 0.2rem $merlin-blue;
    }

.btn-primary {
    background-color: #1a2c74;
    border-color: #1a2c74;

    &:hover {
        background-color: #11193b;
        border-color: #11193b;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem #1a2c74;
    }
}

    .btn-secondary {
        color: #fff;
        background-color: #1a2c74;
        border-color: #132055;
    }

    .btn-secondary:hover {
        color: #fff;
        background-color: #4e2d89;
        border-color: #3c2368;
    }

    a {
        color: #1a2c74;
    }

.btn {
    background: $merlin-blue;
    color: $white;
}

.form-group {
    border: 1px solid #d2d2d2;
    border-radius: .25rem;
    padding: 10px;
}