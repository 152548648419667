﻿.navbar-brand {
    font-size: 0.9em;
    text-transform: uppercase;
    
    img {
        width: 80px;
    }
}

.bg-dark {
    background-color: #1a2c74 !important;
}

.nav-item {
    margin: 10px;
}