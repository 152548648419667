﻿.ul-2-col {
    @include media-breakpoint-up(md) {
        columns: 2;
        list-style: none;
        padding-left: 0;

        li {
            margin-bottom: 7px;
        }
    }
}