﻿



ul.footer-links {
    padding: 0;
    margin-bottom: 25px;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }

    li {
        display: inline-block;
        list-style: none;
        margin: 0 10px;

        @include media-breakpoint-up(md) {
            margin-right: 15px;
        }
    }
}