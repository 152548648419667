﻿.umbraco-forms-field {
    border: 1px solid #d2d2d2;
    border-radius: .25rem;
    padding: 10px;
    margin-bottom: 10px;

    input {
        display: block;
        width: 100%;
        padding: $input-padding-y $input-padding-x;
        font-size: $font-size-base;
        line-height: $input-line-height;
        color: $input-color;
        background-color: $input-bg;
        background-clip: padding-box;
        border: $input-border-width solid $input-border-color;
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;

        &:focus {
            color: #495057;
            background-color: #fff;
            border-color: #b8a2e0;
            outline: 0;
            -webkit-box-shadow: 0 0 0 0.2rem rgba(111,66,193,.25);
            box-shadow: 0 0 0 0.2rem rgba(111,66,193,.25);
        }
        // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
        @if $enable-rounded {
            // Manually use the if/else instead of the mixin to account for iOS override
            border-radius: $input-border-radius;
        }
        @else {
            // Otherwise undo the iOS default
            border-radius: 0;
        }

        @include box-shadow($input-box-shadow);
        @include transition($input-transition);
        // Unstyle the caret on `<select>`s in IE10+.
        &::-ms-expand {
            background-color: transparent;
            border: 0;
        }
        // Customize the `:focus` state to imitate native WebKit styles.
        @include form-control-focus();
        // Placeholder
        &::placeholder {
            color: $input-placeholder-color;
            // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
            opacity: 1;
        }
        // Disabled and read-only inputs
        //
        // HTML5 says that controls under a fieldset > legend:first-child won't be
        // disabled if the fieldset is disabled. Due to implementation difficulty, we
        // don't honor that edge case; we style them as disabled anyway.
        &:disabled,
        &[readonly] {
            background-color: $input-disabled-bg;
            // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
            opacity: 1;
        }
    }

    input:focus {
        box-shadow: 0 0 0 0.2rem $merlin-blue;
    }

    .umbraco-forms-indicator {
        display: none;
    }

    .help-block {
        display: block;
        font-size: 14px;
        margin-bottom: 10px;
    }

    textarea {
        min-height: 100px;
        width: 100%;
        border: 1px solid #ced4da;
        border-radius: .25rem;

        &:focus {
            box-shadow: 0 0 0 0.2rem $merlin-blue;
            -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
            transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        }
    }

    .field-validation-error {
        color: $form-feedback-invalid-color;
    }
}

span.field-validation-valid {
    display: none;
}
