﻿.attractionPanel {
    background: white;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: .25rem;
    padding: 25px;
    box-sizing: border-box;
}

.attractionLogo {
    width: 65%;
}

// Small devices
@media (max-width: 576px) {
    .attractionLogo {
        max-width: 75%;
    }
}

.card-img-top {
    width: 68%;
}

.nav {
    justify-content: center;
}

.nav-tabs {
    margin-bottom: 28px;
    border: none;
    font-size: 1.4rem;

    .nav-link {
        border-radius: .25rem;
        border: 2px solid transparent;

        &.active {
            background-color: #1a2c74;
            border-color: #1a2c74;
            border-width: 2px;
            color: #ffffff;
        }

        &:hover {
            border-color: #1a2c74;
            border-width: 2px;
        }
    }
}

.clickablePanel {
    &:hover {
        background-color: #ededed;
    }
}

.attraction-address {
    white-space: pre-line;
}

